.tosContainer {
    margin:50px;
    text-align: center;
    color:white;
}
p {
    color:#a7a7a7;
}
.btnBack {
    /* display: flex; */
  /* justify-content: flex-start; */
  color: white;
  font-weight: bold;
  align-items: center;
  gap: 0 21px;
  padding: 15px 17px;
  background: rgba(255, 255, 255, 0.22);
  border: 1px solid black;
  border-radius: 10px !important;
  margin-bottom: 12px;
  cursor: pointer;
}