h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0 !important;
}
.mainWrapper {
  display: flex;
  padding: 30px 37px 30px 30px;
}

.dark .nav-tabs .nav-link {
  background-color: transparent !important;

}

.dark .nav-tabs .nav-link.active {
  border-bottom:solid #161616 !important;
}
.authWrapper {
  display: flex;
  padding: 30px 37px 0px 30px;
}
.mainWrapperDark {
  background: #161616;
}
.authWrapperDark {
  background: #161616;
}
.right {
  min-height: 97vh;
  flex: 1 1;
  width: calc(100% - 256px);
  /* overflow-y: scroll;
  overflow-x: hidden; */
}
.main {
  padding: 38px 0 0 32.05px;
  min-height: calc(100vh - 69px);
  position: relative;
}
.gradient {
  background: linear-gradient(
    87.61deg,
    #9f69b4 7.71%,
    #763092 93.23%
  ) !important;
  border: none !important;
  color: #fff !important;
}
/* Arrow  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}
/* myAssets */
.myAssets {
  max-width: 637px;
}
.myAssets .accordion-item {
  border: none;
}
.myAssets .accordion-button {
  height: 62px;
  background: rgba(217, 217, 217, 0.19);
  border-radius: 12.4845px;
  margin-bottom: 13px;
  padding: 13px 29px;
}
.myAssets .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
}
.myAssets .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 13px;
  border-bottom-left-radius: 13px;
}
.myAssets .accordion-button:focus {
  box-shadow: none;
}
.myAssets .accordion-button:not(.collapsed) {
  background: #f6e6fd;
  box-shadow: none;
}
.myAssets .accordion-button:not(.collapsed)::after {
  background-image: url("./assets/Icons/chevronDown.png");
  transform: translateY(0px) rotate(180deg);
  width: 8px;
  height: 4px;
}
.myAssets .accordion-button::after {
  background-image: url("./assets/Icons/chevronDown.png");
  background-size: 8px 4px;
  transform: translateY(0px);
  width: 8px;
  height: 4px;
}
.myAssetsDark {
  background: transparent;
}
.myAssetsDark .accordion-item {
  background: transparent;
}
.myAssetsDark .accordion-button {
  background: rgba(226, 171, 248, 0.3);
}
.myAssetsDark .accordion-button:not(.collapsed) {
  background: rgba(226, 171, 248, 0.4);
}
.myAssetsDark .accordion-button:not(.collapsed)::after {
  background-image: url("./assets/Icons/chevronDownLg.png");
  transform: translateY(0px) rotate(180deg);
  width: 8px;
  height: 4px;
}
.myAssetsDark .accordion-button::after {
  background-image: url("./assets/Icons/chevronDownLg.png");
  background-size: 8px 4px;
  transform: translateY(0px);
  width: 8px;
  height: 4px;
}
/* Settings Accordion */
.settings .accordion-item {
  border: none;
}
.settings .accordion-button {
  height: 65px;
  background: #fdfdfd;
  border: 0.199394px solid rgba(0, 0, 0, 0.2);
  border-radius: 15.9515px;
  padding: 21px 20px 20px 26px;
  margin-bottom: 30px;
}
/* .settings .accordion-button.collapsed {
} */
.settings .accordion-button:focus {
  border: 0.199394px solid rgba(0, 0, 0, 0.2);
}
.settings .accordion-button:not(.collapsed) {
  background: #fdfdfd;
  box-shadow: none;
}
.settings .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 15.9515px;
  border-top-right-radius: 15.9515px;
}
.settings .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 15.9515px;
  border-bottom-left-radius: 15.9515px;
}
.settings .accordion-button:focus {
  box-shadow: none;
}
.settings .accordion-button:not(.collapsed)::after {
  background-image: url("./assets/Icons/chevronDown.png");
  transform: translateY(0px) rotate(180deg);
  width: 8px;
  height: 4px;
  margin-right: 15px;
}
.settings .accordion-button::after {
  background-image: url("./assets/Icons/chevronDown.png");
  background-size: 8px 4px;
  transform: translateY(0px);
  width: 8px;
  height: 4px;
  margin-right: 15px;
}
.settings .accordion-body {
  padding: 0px 20px 43px 20px;
}
/* Dark */
.settingsDark .accordion-item {
  background: transparent;
}
.settingsDark .accordion-button:not(.collapsed) {
  background: #1a1a1a;
  border: 1px solid rgba(255, 255, 255, 0.19);
}
.settingsDark .accordion-button:not(.collapsed)::after {
  background-image: url("./assets/Icons/chevronDownLg.png");
}
/* Settings Accordion  Ended*/

/* settingsDropDown */

.settingsDropDown .p-dropdown {
  width: 131px;
  height: 38px;
  background: rgba(234, 234, 234, 0.42);
  border-radius: 12px;
}

.settingsDropDown .p-dropdown-label {
  padding: 0px 0 0 20px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.settingsDropDown .p-dropdown-trigger {
  width: 35px !important;
  height: 38px;
  background: #fdfdfd !important;
  border: 0.199394px solid rgba(0, 0, 0, 0.5);
  border-radius: 12px !important;
  margin-top: -1px;
  margin-right: -1px;
}
/* Dark */
.settingsDropDownDark .p-dropdown {
  background: rgba(234, 234, 234, 0.12) !important;
}
.settingsDropDownDark .p-dropdown-label {
  color: #ffffff;
}
.settingsDropDownDark .p-dropdown-trigger {
  background: rgba(253, 253, 253, 0.17) !important;
  border: 0.199394px solid rgba(255, 255, 255, 0.2);
}
.settingsDropDownDark .p-dropdown-trigger .pi {
  color: #fff;
}
/* exchangePageInp1 */
.exchangePageInp1 .p-dropdown-trigger {
  margin-right: 29px;
}
.exchangeInp1Act .p-dropdown-trigger {
  margin-right: 0px;
}
.removeMarginRight .p-dropdown-trigger {
  margin-right: 0px;
}
.exchangePageInp1 .p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
}
.exchangePageInp1 .pi {
  font-size: 0.7rem !important;
}
.exchangePageInp1 .p-dropdown-label {
  pointer-events: none;
}
.exchangePageInp2 .p-dropdown-trigger {
  margin-left: 40px;
}
.exchangeInp2Act .p-dropdown-trigger {
  margin-left: 0px;
}
.removeMarginLeft .p-dropdown-trigger {
  margin-left: 0px;
}
.exchangePageInp2 .p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
}
.exchangePageInp2 input {
  position: absolute;
  right: 0;
  width: 70%;
  height: 100%;
}
.exchangePageInp2 .pi {
  font-size: 0.7rem !important;
}
.exchangePageInp1Dark .pi,
.exchangePageInp2Dark .pi {
  color: #fff !important;
}
/* registerDropDown */
.registerDropDown {
  width: 100%;
  height: 66px;
  background: #fdfdfd;
  border: 0.199394px solid rgba(0, 0, 0, 0.4);
  border-radius: 15.9515px !important;
  margin-bottom: 10px;
}
.registerDropDownDark {
  background: rgba(57, 57, 57, 0.19) !important;
  border: none !important;
}
.registerDropDownPanelDark {
  background: #2e2e2e !important;
}
.registerDropDownPanelDark .p-dropdown-header {
  background: #2e2e2e !important;
}
.registerDropDownPanelDark
  .p-dropdown-header
  .p-dropdown-filter-container
  input {
  background: rgba(57, 57, 57, 0.19);
  color: #ffffff;
}
.registerDropDownPanelDark .p-dropdown-items .p-dropdown-item {
  color: #ffffff !important;
}
.registerDropDownPanelDark .p-dropdown-items .p-dropdown-empty-message {
  color: #ffffff !important;
}
.p-dropdown-panel
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background: rgba(90, 90, 90, 0.19) !important;
}

.registerDropDown .p-dropdown-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 37px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
}
.registerDropDown .p-dropdown-trigger {
  margin-right: 15px;
}
.registerDropDown .p-dropdown-trigger .pi {
  font-size: 12px !important;
  color: black !important;
}

.registerDropDown .image-text {
  vertical-align: middle;
  margin-left: 0.5rem;
}

.registerDropDown .p-multiselect-representative-option {
  display: inline-block;
  vertical-align: middle;
}

.registerDropDown .p-multiselect-representative-option img {
  vertical-align: middle;
  width: 24px;
}

.registerDropDown .p-multiselect-representative-option span {
  margin-top: 0.125rem;
}
.registerDropDownDark .p-dropdown-label {
  color: #9a9a9a;
}
.registerDropDownDark .p-dropdown-label.p-placeholder {
  color: #9a9a9a !important;
}
.registerDropDownPanelDark .p-dropdown-items .p-dropdown-item.p-highlight {
  background: #012 !important;
}
.p-column-filter {
  width: 100%;
}

.country-item {
  display: flex;
  align-items: center;
}

.country-item img.flag {
  width: 18px;
  margin-right: 0.5rem;
}

.flag {
  vertical-align: middle;
  background: url("https://www.primefaces.org/primereact/images/flag/flags_responsive.png")
    no-repeat;
  background-size: 100%;
  vertical-align: middle;
}

.registerDropDown span.flag {
  width: 44px;
  height: 30px;
  display: inline-block;
}

.registerDropDown img.flag {
  width: 30px;
}
.registerDropDownDark .p-dropdown-trigger .pi {
  color: #fff !important;
}
/* Mobile Verification DropDown */
.mobVerDropDown {
  /* max-width: 90px;
  width: 100%; */
  height: 66px;
  background: #fdfdfd;
  border: 0.199394px solid rgba(0, 0, 0, 0.2);
  border-radius: 15.9515px !important;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.mobVerDropDown .p-dropdown-trigger .pi {
  font-size: 10px !important;
  color: black !important;
}
.p-dropdown:not(.p-disabled).p-focus {
  box-shadow: none !important;
}

/* Panel */
.panelDark {
  background: rgba(31, 31, 31) !important;
}
.panelDark .p-dropdown-items .p-dropdown-item {
  color: #e3e3e3 !important;
}
.panelDark
  .p-dropdown-items
  .p-dropdown-item:not(.p-highlight):not(.p-disabled):hover {
  background: rgba(54, 54, 54, 0.19) !important;
}

/* tickHidden */
.tickVisible {
  visibility: visible !important;
}

/* Pagination */
.page-item:first-child .page-link {
  /* border-color: transparent; */
}
.page-item .page-link {
  color: #000000;
  border-radius: 7.42857px !important;
  border: 0.164579px solid rgba(0, 0, 0, 0.16);
}
.dark .page-item .page-link {
  border-radius: 12px !important;
  width: 52px;
  height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0.164579px solid rgba(255, 255, 255, 0.16);
  color: #ffffff;
  cursor: pointer;
  background: #1a1a1a;
}

.active > .page-link {
  background-color: #1a1a1a !important;
  border: 0.164579px solid rgba(255, 255, 255, 0.16);
  border-color: #ffffff !important;
  background: linear-gradient(87.61deg, #9f69b4 7.71%, #763092 93.23%);
  opacity: 0.5;
  pointer-events: none;
}
.dark .active > .page-link {
  background-color: #1a1a1a !important;
  border: 0.164579px solid rgba(255, 255, 255, 0.16);
  border-color: #ffffff !important;
  background: linear-gradient(87.61deg, #9f69b4 7.71%, #763092 93.23%);
  opacity: 0.5;
  pointer-events: none;
}

/* Background blur  */
.p-dialog-mask.p-component-overlay {
  backdrop-filter: blur(2px);
}
.modal {
  backdrop-filter: blur(2px);
}
/* Earn page popup */

.earnPagePopup {
  max-width: 518px;
  width: 100%;
  border-radius: 32px;
  box-shadow: none !important;
}
.earnPagePopup .p-dialog-header {
  border-top-right-radius: 32px !important;
  border-top-left-radius: 32px !important;
  padding: 27px 31px 14px 28px !important;
}
.earnPagePopup .p-dialog-header .p-dialog-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14.6761px !important;
  line-height: 22px;
  color: #000000;
}
.earnPagePopup .p-dialog-header-icons {
  width: 32px;
  height: 32px;
  background: rgba(217, 217, 217, 0.24);
  border-radius: 7px;
}
.earnPagePopup .p-dialog-header .p-dialog-header-icon {
  border-radius: 7px !important;
}

.earnPagePopup .p-dialog-header .p-dialog-header-icon .pi {
  color: #000;
}
.earnPagePopup .p-dialog-content:last-of-type {
  border-bottom-right-radius: 32px !important;
  border-bottom-left-radius: 32px !important;
  padding: 0 27px 30px 28px !important;
}
.earnPagePopup .p-dialog-content label {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14.6761px;
  line-height: 22px;
  color: #d6d6d6;
  margin-bottom: 12px !important;
}
.earnPagePopup .p-dialog-content button {
  /* width: 100%;
  height: 60px;
  background: linear-gradient(87.61deg, #9f69b4 7.71%, #763092 93.23%);
  border-radius: 17px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14.6761px;
  line-height: 22px;
  color: #ffffff; */
}
.calender {
  max-width: 154.93px !important;
  width: 100%;
  height: 37.6px;
  background: rgba(72, 133, 254, 0.18);
  border-radius: 12px;
}
.calender input {
  background: transparent;
  border: none;
}
.calender button {
  width: 35px !important;
  height: 38px;
  background: #eaeaea;
  border-radius: 12px !important;
  color: #000;
  border: none;
}
.calender button .pi-calendar::before {
  content: "\e930" !important;
}
.calender .p-button:enabled:hover,
.calender .p-button:enabled:active {
  background: rgba(72, 133, 254, 0.18);
  border-color: #eaeaea;
  color: #000;
}
.p-dialog {
  box-shadow: none !important;
}
/* Dark */
.earnPagePopupDark .p-dialog-header,
.earnPagePopupDark .p-dialog-content {
  background: #161616 !important;
}
.earnPagePopupDark .p-dialog-header .p-dialog-header-icon {
  background: rgba(82, 82, 82, 0.24) !important;
}
.earnPagePopupDark .p-dialog-header .p-dialog-header-icon .pi {
  color: #ffffff;
}
.earnPagePopupDark .p-dialog-header .p-dialog-title {
  color: #ffffff;
}
/* Earn page popup ended*/

/* earnPageSuccessPopup start */
.earnPageSuccessPopupMain {
  align-items: center !important;
}
.earnPageSuccessPopup {
  max-width: 518px;
  width: 100%;
}
.earnPageSuccessPopup .p-dialog-header {
  padding: 24px 34px 0 !important;
  border-top-right-radius: 32px !important;
  border-top-left-radius: 32px !important;
}
.earnPageSuccessPopup .p-dialog-header-icons {
  width: 32px;
  height: 32px;
  background: rgba(217, 217, 217, 0.24);
  border-radius: 7px;
}
.earnPageSuccessPopup .p-dialog-content:last-of-type {
  border-bottom-right-radius: 32px !important;
  border-bottom-left-radius: 32px !important;
}
.earnPageSuccessPopup .p-dialog-header .p-dialog-header-icon {
  border-radius: 7px !important;
}

.earnPageSuccessPopup .p-dialog-header .p-dialog-header-icon .pi {
  color: #000;
}
.earnPageSuccessPopupDark .p-dialog-header,
.earnPageSuccessPopupDark .p-dialog-content {
  background: #161616 !important;
}
.earnPageSuccessPopupDark .p-dialog-header .p-dialog-header-icon {
  background: rgba(217, 217, 217, 0.24) !important;
}
.earnPageSuccessPopupDark .p-dialog-header .p-dialog-header-icon span {
  color: #fff !important;
}
/* earnPageSuccessPopup ended */

/* walletPageBankPopup started */
.walletPageBankPopup {
  max-width: 692px;
  width: 100%;
}
.walletPageBankPopup .p-dialog-header {
  padding: 14px 35px 5px 46px !important;
  border-top-right-radius: 32px !important;
  border-top-left-radius: 32px !important;
}
.walletPageBankPopup .p-dialog-header .p-dialog-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14.6761px !important;
  line-height: 22px;
  color: #000000;
}
.walletPageBankPopup .p-dialog-content:last-of-type {
  padding: 0 35px 34px 38px !important;
  border-bottom-right-radius: 32px !important;
  border-bottom-left-radius: 32px !important;
}
.walletPageBankPopup .p-dialog-header-icons {
  width: 32px;
  height: 32px;
  background: rgba(217, 217, 217, 0.24);
  border-radius: 7px;
}
.walletPageBankPopup .p-dialog-header .p-dialog-header-icon {
  border-radius: 7px !important;
}

.walletPageBankPopup .p-dialog-header .p-dialog-header-icon .pi {
  color: #000;
}
/* Dark */
.walletPageBankPopupDark .p-dialog-header,
.walletPageBankPopupDark .p-dialog-content {
  background: #161616 !important;
}
.walletPageBankPopupDark .p-dialog-header .p-dialog-title {
  color: #fff;
}
.walletPageBankPopupDark .p-dialog-header .p-dialog-header-icon .pi {
  color: #fff;
}
.walletPageBankPopupDark .p-dialog-header .p-dialog-header-icon {
  background: rgba(217, 217, 217, 0.24) !important;
}

/* walletPageBankPopup ended*/

/* transactionDetailsPopup Started */

.transactionDetailsPopup {
}
.transactionDetailsPopup {
  max-width: 460px;
  width: 100%;
}
.transactionDetailsPopup .p-dialog-header {
  padding: 14px 35px 5px 60px !important;
  border-top-right-radius: 32px !important;
  border-top-left-radius: 32px !important;
}
.transactionDetailsPopup .p-dialog-header .p-dialog-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 17.6761px !important;
  line-height: 22px;
  text-align: center;
  color: #000000;
  text-transform: capitalize;
}
.transactionDetailsPopup .p-dialog-content:last-of-type {
  padding: 0 35px 34px 38px !important;
  border-bottom-right-radius: 32px !important;
  border-bottom-left-radius: 32px !important;
  margin-top: -5px;
}
.transactionDetailsPopup .p-dialog-header-icons {
  width: 32px;
  height: 32px;
  background: rgba(217, 217, 217, 0.24);
  border-radius: 7px;
}
.transactionDetailsPopup .p-dialog-header .p-dialog-header-icon {
  border-radius: 7px !important;
}

.transactionDetailsPopup .p-dialog-header .p-dialog-header-icon .pi {
  color: #000;
}
/* Dark */
.transactionDetailsPopupDark .p-dialog-header,
.transactionDetailsPopupDark .p-dialog-content {
  background: #161616 !important;
}
.transactionDetailsPopupDark .p-dialog-header .p-dialog-title {
  color: #fff;
}
.transactionDetailsPopupDark .p-dialog-header .p-dialog-header-icon .pi {
  color: #fff;
}
.transactionDetailsPopupDark .p-dialog-header .p-dialog-header-icon {
  background: rgba(217, 217, 217, 0.24) !important;
}
/* transactionDetailsPopup ended */

/* intentPopup started */
.intentPopup {
  max-width: 518px;
  width: 100%;
}
.intentPopup .p-dialog-header {
  padding: 14px 35px 5px 46px !important;
  border-top-right-radius: 32px !important;
  border-top-left-radius: 32px !important;
}
.intentPopup .p-dialog-content:last-of-type {
  padding: 0 35px 34px 38px !important;
  border-bottom-right-radius: 32px !important;
  border-bottom-left-radius: 32px !important;
}
.intentPopup .p-dialog-header .p-dialog-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14.6761px !important;
  line-height: 22px;
  color: #000000;
}
.intentPopup .p-dialog-header-icons {
  width: 32px;
  height: 32px;
  background: rgba(217, 217, 217, 0.24);
  border-radius: 7px;
}
.intentPopup .p-dialog-header .p-dialog-header-icon {
  border-radius: 7px !important;
}

.intentPopup .p-dialog-header .p-dialog-header-icon .pi {
  color: #000;
}
/* Dark */
.intentPopupDark {
}
.intentPopupDark .p-dialog-header-icons {
  background-color: rgba(82, 82, 82, 0.24);
}
.intentPopupDark .p-dialog-header .p-dialog-header-icon .pi {
  color: #fff;
}
.intentPopupDark .p-dialog-header,
.intentPopupDark .p-dialog-content {
  background: #161616 !important;
}
.intentPopupDark .p-dialog-header .p-dialog-title {
  color: #fff;
}
/* intentPopup ended */

/* walletPageInitialModal started */
.walletPageInitialModal .modal-dialog {
  max-width: 692px;
}
.walletPageInitialModal .modal-dialog .modal-content {
  border-radius: 32px;
}
.walletPageInitialModal .modal-header {
  border-bottom: none;
  padding: 29px 34px 5px 47px;
}
.walletPageInitialModal .modal-header button {
  width: 32px;
  height: 32px;
  background-color: rgba(217, 217, 217, 0.24);
  border-radius: 7px;
}
.walletPageInitialModal .modal-body {
  padding: 0px !important;
}
/* Dark */
.walletPageInitialModalDark .modal-header button {
  background-color: rgba(82, 82, 82, 0.24);
  background: rgba(82, 82, 82, 0.24) url("./assets/Icons/Close_MD.svg")
    center/1.8em auto no-repeat;
}
.walletPageInitialModalDark .modal-dialog .modal-content {
  background: #161616;
}
/* walletPageInitialModal ended */

.currencyForDepositDropDownDark {
  background: #161616 !important;
}
.currencyForDepositDropDownDark li {
  color: #7a7a7a !important;
}
.currencyForDepositDropDownDark .p-dropdown-items .p-dropdown-item.p-highlight {
  background: rgba(90, 90, 90, 0.19) !important;
}
.stripePopupDark .p-dialog-header,
.stripePopupDark .p-dialog-content {
  background: #161616 !important;
}

.stripeDark .p-Input {
  background: #161616 !important;
}

/* memberShipPagePaymentModal */
.memberShipPagePaymentModal {
  max-width: 692px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 32px !important;
}
.memberShipPagePaymentModal .p-dialog-header {
  padding: 29px 34px 0px 47px !important;
  border-top-right-radius: 32px !important;
  border-top-left-radius: 32px !important;
}
.memberShipPagePaymentModal .p-dialog-content:last-of-type {
  border-bottom-right-radius: 32px !important;
  border-bottom-left-radius: 32px !important;
  padding: 0 34px 28px 35px;
}
.memberShipPagePaymentModal .p-dialog-header .p-dialog-title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 500 !important;
  font-size: 14.6761px !important;
  line-height: 22px;
  color: #000000;
}
.memberShipPagePaymentModal .p-dialog-header .p-dialog-header-icon {
  width: 32px !important;
  height: 32px !important;
  background: rgba(217, 217, 217, 0.24) !important;
  border-radius: 7px !important;
}
.memberShipPagePaymentModal .p-dialog-header .p-dialog-header-icon .pi {
  color: #000;
}
.memberShipPagePaymentModalDark {
  background: #161616;
}
.memberShipPagePaymentModalDark .p-dialog-header,
.memberShipPagePaymentModalDark .p-dialog-content {
  background: #161616 !important;
}
.memberShipPagePaymentModalDark .p-dialog-header .p-dialog-header-icon .pi {
  color: #fff;
}
.memberShipPagePaymentModalDark .p-dialog-header .p-dialog-title {
  color: #fff;
}

/* depositNewModalWrapper */
.depositNewModalWrapper {
  max-width: 890px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #e6e6e6;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04);
  border-radius: 32px !important;
}
.depositNewModalWrapper .p-dialog-header {
  padding: 1.5rem 1.5rem 0 1.5rem;
  border-top-right-radius: 32px !important;
  border-top-left-radius: 32px !important;
}
.depositNewModalWrapper .p-dialog-header .p-dialog-header-icon {
  width: 32px !important;
  height: 32px !important;
  background: rgba(217, 217, 217, 0.24) !important;
  border-radius: 7px !important;
}
.depositNewModalWrapper .p-dialog-content {
  border-bottom-right-radius: 32px !important;
  border-bottom-left-radius: 32px !important;
}
.depositNewModalWrapper .p-dialog-header .p-dialog-header-icon .pi {
  color: #000;
}
.depositNewModalWrapperDark .p-dialog-header {
  background: #161616 !important;
}
.depositNewModalWrapperDark .p-dialog-header .p-dialog-header-icon .pi {
  color: #eee;
}
.depositNewModalWrapperDark {
  background: transparent;
}
.depositNewModalWrapperDark .p-dialog-content {
  background: #161616 !important;
}
.confirmPopup .p-confirm-popup-reject {
  background: linear-gradient(87.61deg, #9f69b4 7.71%, #763092 93.23%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.confirmPopup .p-confirm-popup-accept {
  background: linear-gradient(
    87.61deg,
    #9f69b4 7.71%,
    #763092 93.23%
  ) !important;
  border: none !important;
}
/* .confirmPopupDark:before {
  border-bottom-color: rgba(20, 20, 20) !important;
}
.confirmPopupDark:after {
  border-bottom-color: rgba(20, 20, 20) !important;
} */
.confirmPopupDark {
  background: rgba(20, 20, 20) !important;
}
.confirmPopupDark .p-confirm-popup-message {
  color: rgba(190, 190, 190);
}
.confirmPopupDark .p-confirm-popup-icon {
  color: rgba(190, 190, 190);
}
/* Media Queries */
/* Media Queries */
@media screen and (max-width: 998px) {
  .main {
    padding: 38px 0 0 0;
  }
  .sideBarOpen {
    left: 30px !important;
    transition: all 0.5s;
    top: 18px;
  }
  .sidePanelOpen {
    left: 0px !important;
    transition: all 0.5s;
  }
}
@media screen and (max-width: 768px) {
  .walletPageInitialModal .modal-content {
    max-width: 100%;
    /* width: 85%; */
    margin: 0 auto;
  }
  .walletPageBankPopup {
    max-width: 100%;
    width: 85%;
    margin: 0 auto;
  }
  .memberShipPagePaymentModal {
    max-width: 100%;
    width: 85%;
  }
}
@media screen and (max-width: 600px) {
  .walletPageInitialModal {
  }
  .sideDark .p-sidebar {
    background: rgba(28, 28, 28) !important;
  }
  .earnPagePopup {
    max-width: 320px;
  }
  .earnPagePopup .p-dialog-content button {
    height: 48px;
  }
  .intentPopup {
    max-width: 100%;
    width: 85%;
  }
  .memberShipPagePaymentModal .p-dialog-header {
    padding: 16px 16px 0px 23px !important;
  }
  .memberShipPagePaymentModal .p-dialog-content:last-of-type {
    padding: 0 16px 18px 23px;
  }
}
@media screen and (max-width: 500px) {
  .mainWrapper {
    padding: 15px;
  }
  .earnPageSuccessPopup .p-dialog-header {
    padding: 13px 15px 0 !important;
  }
  .memberShipPagePaymentModal {
    max-width: 100%;
    width: 95%;
  }
}
@media screen and (max-width: 400px) {
  .earnPagePopup {
    max-width: 280px;
  }
  .earnPagePopup .p-dialog-content label {
    font-size: 12px;
    margin-bottom: 3px !important;
  }
  .earnPagePopup .p-dialog-header .p-dialog-title {
    font-size: 12px !important;
  }
  .earnPagePopup .p-dialog-header {
    padding: 15px 14px 9px 18px !important;
  }
  .earnPagePopup .p-dialog-content:last-of-type {
    padding: 0 16px 16px 16px !important;
  }
  .earnPagePopup .p-dialog-content button {
    height: 45px;
    border-radius: 6px;
  }
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
}

.tooltip:hover .tooltiptext {
  width: fit-content;
  white-space: nowrap;
  visibility: visible;
  opacity: 1;
}
.tooltip {
  cursor: default;
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  border: 1px solid #1c1e36;
  visibility: hidden;
  width: 120px;
  background-color: #3b3e59;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 10px;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip .tooltiptext::after {
  content: "";
  position: absolute;
}

.tooltip:hover .tooltiptext {
  width: fit-content;
  white-space: nowrap;
  visibility: visible;
  opacity: 1;
}

.asics-tab {
  font-size: 20px;
  padding: 10px 20px;
  font-family: "Poppins", sans-serif;
  color: #72049d;
}



.asics-card {
  background-color: rgba(248, 248, 248);
  width: 300px;
  border-radius: 22px;
}

.asics-card-dark{
  background-color: rgba(82, 82, 82, 0.24);
  width: 300px;
  border-radius: 22px;
}

.asics-button {
  width: 100%;
  height: 40px;
  background: linear-gradient(87.61deg, #9f69b4 7.71%, #763092 93.23%);
  border-radius: 17px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14.6761px;
  line-height: 22px;
  color: #ffffff;
}

.asics-button:hover {
  background: linear-gradient(-87.61deg, #9f69b4 7.71%, #763092 93.23%);
}

.asics-buy-button {
  width: 200px;
  height: 40px;
  background: linear-gradient(87.61deg, #9f69b4 7.71%, #763092 93.23%);
  border-radius: 17px;
  border: none;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 14.6761px;
  line-height: 22px;
  color: #ffffff;
}

.asics-buy-button:hover {
  background: linear-gradient(-87.61deg, #9f69b4 7.71%, #763092 93.23%);
}

.asics-disclaimer {
  border-width: 1px;
  border-color: red;
  text-align: center;
  border-style: solid;
  width: 450px;
  font-size: 10px;
  padding: 5px;
}

.asics-description-buy {
  border-width: 1px;
  border-color: green;
  text-align: center;
  border-style: solid;
  width: 450px;
  font-size: 15px;
  padding: 5px;
}

.text-red {
  color: red;
}

.text-white {
  color: white;
}

.border-red {
  border-width: 1px;
  border-color: red;
  border-style: solid;
  border-radius: 17px;
  padding: 6px;
}
